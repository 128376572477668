import Vue from 'vue'
import App from './App.vue'
import router from './router'
import _ from 'lodash'
import { MaskPlugin, brMasks, brMoneyOptions } from 'vue2-mask'
import { MessagePlugin }  from 'vue2-message'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueSweetalert2 from 'vue-sweetalert2';

import 'bootstrap/dist/js/bootstrap.min.js'
import "./assets/js/ruang-admin.js"
import "./assets/js/dataTables.bootstrap4.min.js"
import "./assets/js/jquery.dataTables.min.js"
import '@fortawesome/fontawesome-free/css/all.min.css'
import "noty/lib/noty.css"
import "noty/lib/themes/sunset.css"
import "noty/lib/themes/bootstrap-v4.css"
import "noty/lib/themes/relax.css"
import "./assets/css/style.css"
import "./assets/css/app/app.css"
import "./assets/css/ruang-admin.min.css"
import './assets/css/clock-picker.css'
import './assets/css/dataTables.bootstrap4.min.css'

async function main() {

    if (process.env.NODE_ENV === 'development') {
        await import('./index-dev')
    }

    // mask setup
    MaskPlugin.addMasks(brMasks)
    MaskPlugin.moneyOptions = _.extend(brMoneyOptions, {
        selectAllOnFocus: true,
        allowEmpty: true,
        allowZero: true,
    })
    Vue.use(MaskPlugin)

    // message setup
    Vue.use(MessagePlugin)

    // sweet Alert 2 para mensagens de confirmação
    Vue.use(VueSweetalert2);
    
    new Vue({
        el: '#sesApp',
        router,
        render: h => h(App)
    })
}

main()
