













































































































import UsuarioListing from "./UsuarioListing.script";
import hmr from "vue2-hmr/dist";

export default hmr(UsuarioListing, module);
