import $ from 'jquery'
import { Component, Vue, Watch } from 'vue-property-decorator'
import PageMixin from 'vue2-page-mixin'
import store from '../../store'
import 'jquery-mask-plugin'
import 'bootstrap-filestyle'
import Messages from 'vue2-messages'
import { VueSelect } from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Pagination from '../../components/Pagination.vue'
import { bind } from 'lodash'

interface UsuarioListing extends PageMixin {}

@Component({
    components: {'v-select': VueSelect, 'paginacao': Pagination},
    mixins: [PageMixin],
    store,
})
class UsuarioListing extends Vue implements UsuarioListing {

    name = 'UsuarioListing'

    tiposUsuarios = [];

    model = {
        nome: '',
        cpf: '',
        unidade: ''
    }

    filtro = {
        nome: null,
        cpf: null,
        tipoUsuario: null,
        excluido: false
    }
    
    usuarios = []
    limit = 10
    limits = [10, 20, 50, 100]
    page = 1
    totalPages = 0
    pagination = {totalCount: 1}

    pageChanged (page) {            
        this.page = page
        this.filtrarUsuario(page);
    }

    limitSelect(){
        this.page = 1
        this.buscarUsuarios();
    }

    created() {
        this.buscarTiposUsuario().then(res => {
            this.tiposUsuarios = res.data.tiposUsuario;
        });
        this.limpar();
    }

    buscarTiposUsuario(){
        return this.http().get(`${this.serverUrl}/tipo-usuario/listar-tipo-usuario/`);
    }

    mounted() {
        
    }

    filtrarUsuario(page) {
        this.http().get(`${this.serverUrl}/usuario/filtrar-usuario?filtros=${JSON.stringify(this.filtro)}&page=${page}&per-page=${this.limit}`)
            .then(res => {
                this.usuarios = res.data.usuarios
                this.pagination = res.data?.pagination
                this.totalPages = Math.ceil(this.pagination?.totalCount / this.limit)
                $('[data-toggle="tooltip"]').tooltip()
            })
    }

    buscarUsuarios() {
        this.http().get(`${this.serverUrl}/usuario/${this.page}/${this.limit}`).then(res => this.usuarios = res.data.usuarios)
    }

    limpar(){
        this.filtro = {nome: null, cpf: null, tipoUsuario: null, excluido: false};
        this.filtrarUsuario(1);
    }

    resetarSenha(idUsuario) {
        this.http().post(`${this.serverUrl}/usuario/resetar-senha`, {id: idUsuario})
            .then(resp => {
                if (resp.data != null) {
                    Messages.success('Senha Resetada')
                } else {
                    Messages.error('Não foi possível resetar a senha')
                }
            })
            .catch(() => {})
    }

    confirmarResetarSenha(idUsuario) {
        this.mensagemConfirmacao(
            'Tem certeza que deseja Resetar a senha desse usuário',
            'Faça sua escolha',
            'Senha Resetado com Sucesso',
            this.resetarSenha.bind(null, idUsuario)
        )
    }

    confirmarAcao(id, tipoAcao) {
        var mensagemFalha = "Ocorreu uma falha ao executar a ação";
        if(tipoAcao == 'excluir'){
            var mensagem = "Tem certeza que deseja Excluir esse Usuário?";
            var mensagemSucesso = "Exclusão realizada com sucesso";
        }else if(tipoAcao == 'recuperar'){
            var mensagem = "Tem certeza que deseja Recuperar esse Usuário?";
            var mensagemSucesso = "Recuperação realizada com sucesso";
        }

        this.mensagemConfirmacao(
            mensagem, 
            'Faça sua escolha', 
            mensagemSucesso, 
            this.executarAcao.bind(null, mensagemSucesso, mensagemFalha, tipoAcao, id)
          )
    }
    
    executarAcao(mensagemSucesso, mensagemFalha, tipoAcao, id) {
        this.http().post(`${this.serverUrl}/usuario/excluir-recuperar`, {id: id, tipo: tipoAcao})
            .then(resp => {
                if (resp.data != null) {
                    Messages.success(mensagemSucesso)
                    this.filtrarUsuario(this.page)
                    this.$swal.fire(resp.data.msg, '', 'success');
                } else {
                    Messages.success(mensagemFalha)
                }
            })
            .catch(() => {})
    }

    mensagemConfirmacao(titulo, corpoMensagem, mensagemPosConfirmacao, callback){
        this.$swal({
          title: titulo,
          html: corpoMensagem,
          showCloseButton: false,
          showCancelButton: true,
          focusConfirm: false,
          cancelButtonText: `Cancelar`,
          confirmButtonText: `Ok`,
          icon: 'warning'
        }).then((result) => {
          if (result.isConfirmed) {
            callback()
          } else if (result.isDismissed) {
            this.$swal.fire('Operação cancelada', '', 'info')
          }
        })
    }
}

export default UsuarioListing